























import {Component, Vue} from "vue-property-decorator";
import ElectronApiPlaceholder from "@/electron/ElectronApiPlaceholder";
import globalStore from "@/globalstore";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import {remote} from "electron";
import {isIOS, isMobileSafari, isOpera, isMobile} from "mobile-device-detect";
import Questionnaire from '@/components/QuestionnaireModal.vue'

/*

ipcRenderer.on('update_available', () => {
    ipcRenderer.removeAllListeners('update_available');
    console.log('A new update is available. Downloading now...');
});
ipcRenderer.on('update_downloaded', () => {
    ipcRenderer.removeAllListeners('update_downloaded');
    console.log('Update Downloaded. It will be installed on restart. Restart now?');
});
 */

const DEFAULT_TRANSITION = `slide`;
const DEFAULT_TRANSITION_MODE = `out-in`;
@Component({
    components: {LoadingIndicator, Questionnaire}
})
export default class App extends Vue {
    transitionName: string = DEFAULT_TRANSITION;
    transitionMode: string | null = DEFAULT_TRANSITION_MODE;
    transitionEnterActiveClass: string | null = ``;
    isRotated = new Boolean;
    showQuestionnaire = !this.isQuestionnaireFinished;

    constructor() {
        super();

        // @ts-ignore
        globalStore.electronApi = window.electronApi ?? new ElectronApiPlaceholder();
        // eslint-disable-next-line no-console
        // console.log(`App running on Electron ${globalStore.electronApi.isElectron()}`);

        //const app = remote.app;
        //alert("Userdata " + app.getPath('userData'));
    }

    mounted() {
        window.addEventListener("DOMContentLoaded", this.handleOrientationChange);
        window.addEventListener("orientationchange",this.handleOrientationChange);

        window.addEventListener('resize', this.resizeHeight);

        var lastTouchEnd = 0;
        document.addEventListener('touchend', function (event) {
        var now = (new Date()).getTime();
        if (now - lastTouchEnd <= 300) {
            event.preventDefault();
        }
        lastTouchEnd = now;
        }, {passive: false});
    }

    resizeHeight(){
        var height = window.innerHeight;
        setInterval(function() {
            window.scroll(0,-1);
            resize();
        }, 500);

        var resize = function() {
        if(window.innerHeight != height) {
            height = window.innerHeight;
            document.body.style.height = height + "px";
        }
        };
    }

    handleOrientationChange() {
        if(isMobile){
            if(isIOS ||(isMobileSafari || isOpera)){
                var orientation = window.orientation;
                //const orientation = window.innerWidth > window.innerHeight ? "Landscape" : "Portrait";
                switch(orientation){
                    case 0:
                    case -0:
                        this.isRotated = false;
                        break;
                    case 90:
                    case -90:
                        this.isRotated = true;
                        break;
                }
            }else{
                const orientation = window.screen.orientation.type;
                switch(orientation){
                    case "portrait-primary":
                    case "portrait-secondary":
                        this.isRotated = false;
                        break;
                    case "landscape-primary":
                    case "landscape-secondary":
                        this.isRotated = true;
                        break;
                }
            //console.log(orientation);
            }
        }   
    }

    get isQuestionnaireFinished(): boolean {
      return !!Number(this.getCookie("questionnaire_done"));
    }

  getCookie(cname: string) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }

    get isChangingLocale(): boolean {
        return globalStore.localeManager.isChangingLocale;
    }

    menu(e: MouseEvent) {
        if (globalStore.electronApi.isElectron())
            e.preventDefault()
    }

    created() {
        // console.log("Router setup")
        this.$router.beforeEach((to, from, next) => {
            let transitionName = to.meta.transitionName ?? from.meta.transitionName ?? DEFAULT_TRANSITION;
            if (to.name == "machine-benefit-item" && (from.name == "machine-benefits" || from.name == "machine-benefit-item")) {
                transitionName = "none";
            } else if (transitionName === `slide`) {
                const toDepth = to.path.split(`/`).filter(el => el).length;
                const fromDepth = from.path.split(`/`).filter(el => el).length;

                // console.log(`to=${toDepth} (${to.path}) from=${fromDepth}(${from.path})`);

                // console.log(toDepth, fromDepth, toDepth==fromDepth);
                if (toDepth != fromDepth) {
                    transitionName = toDepth < fromDepth ? `slide-right` : `slide-left`;
                } else {
                    transitionName = `slide-left`//"fade"
                }
            }
            this.transitionMode = DEFAULT_TRANSITION_MODE;
            this.transitionEnterActiveClass = `${transitionName}-enter-active`;
            if (to.meta.transitionName === `zoom`) {
                this.transitionMode = `in-out`;
                this.transitionEnterActiveClass = `zoom-enter-active`;
                document.body.style.overflow = `hidden`;
            }
            if (from.meta.transitionName === `zoom`) {
                this.transitionMode = null;
                this.transitionEnterActiveClass = null;
                document.body.style.overflow = '';
            }

            this.transitionName = transitionName;
            next();
        });
    }
}
