import {analytics} from "./FirebaseConfig";
import isElectron, {isPWA} from "@/utils/ElectronCheck";
import router from "@/router";
import {Route} from "vue-router";
import {Vue} from 'vue-property-decorator';
import Item = firebase.analytics.Item;

export default class Tracker {
    constructor() {
        Vue.prototype.$tracker = this;
    }

    setCurrentScreen(title: string) {
        analytics.setCurrentScreen(title)
    }

    trackRoute(route: Route) {
        analytics.logEvent("page_view", {
            // page_title: route,
            page_path: router.currentRoute.fullPath
        });
        this.setView(route);
        this.setCurrentScreen(route.name!!)
    }

    viewItemsList(items: Item[]) {
        analytics.logEvent('view_item_list', {
            items: items
        })
    }

    viewItems(items: Item[]) {
        analytics.logEvent('view_item', {
            items: items
        })
    }

    selectContent(id: string, items: Item[] | undefined = undefined, contentType: string | undefined = undefined) {
        analytics.logEvent('select_content', {
            content_id: id,
            items: items,
            content_type: contentType
        })
    }

    trackVideo(id: string, title: string | undefined = undefined, playing: boolean) {
        analytics.logEvent('video', {
            item_id: id,
            item_title: title,
            playing: playing.toString()
        })
    }

    trackEvent(name: string) {
        analytics.logEvent(name, {})
    }

    trackDownload(id: string, title: string | undefined, platform: string){
        analytics.logEvent('download_installer', {
            app_id : id,
            app_title : title,
            platform : platform
        })
    }

    trackUrlOpen(url: string, type: string, title: string | undefined = undefined) {
        analytics.logEvent('open_url', {
            item_url: url,
            item_title: title,
            content_type: type
        })
        analytics.logEvent(url);
    }

    setView(route: Route = router.currentRoute) {
        analytics.logEvent("screen_view", {
            app_name: isElectron() ? "electron" : isPWA() ? "pwa" : "web",
            screen_name: route.name ?? undefined,
            app_version: process.env.VUE_APP_VERSION
        });
    }

    trackSearch(query: string) {
        analytics.logEvent("search", {
            search_term: query
        })
    }

    exception(error: string) {
        analytics.logEvent('exception', {
            description: error
        })
    }

    userLanguage(language: string) {
        analytics.setUserProperties({
                app_language: language
            }
        )
    }
}


declare module 'vue/types/vue' {

    interface Vue {
        $tracker: Tracker
    }
}