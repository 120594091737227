import { render, staticRenderFns } from "./Interstitial.vue?vue&type=template&id=85d8ad68&scoped=true&"
import script from "./Interstitial.vue?vue&type=script&lang=ts&"
export * from "./Interstitial.vue?vue&type=script&lang=ts&"
import style0 from "./Interstitial.vue?vue&type=style&index=0&id=85d8ad68&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85d8ad68",
  null
  
)

export default component.exports